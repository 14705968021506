import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

const ContactTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component--contact-tile bg-black pt-30 pb-30 xl:pt-60.5 xl:pb-65.5" ref={sectionEl}>
      <div className="max-w-1440px mx-auto md:text-center xl:text-left font-normal px-8 xl:px-0">
        <h2 className="text-orange text-lg leading-6 xl:leading-9 xl:text-3xl mb-3">{props.data.title}</h2>
        <a className="text-white block font-heading text-2xl leading-7 xl:leading-normal xl:text-120px underline hover:no-underline" href={'mailto:' + props.data.email}>{props.data.email}</a>
      </div>
    </section>
  );
}

export default ContactTile;
