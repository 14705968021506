import React from "react";

interface IProps {
  color?: string;
}

const IconShape2: React.FC<IProps> = ({
  color = '#ff6e56'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36" viewBox="0 0 36 36">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_627" data-name="Rectangle 627" width="36" height="36" fill={color}/>
        </clipPath>
      </defs>
      <g id="Group_330" data-name="Group 330" transform="translate(0 0)">
        <g id="Group_329" data-name="Group 329" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path id="Path_837" data-name="Path 837" d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18" transform="translate(0 0)" fill={color}/>
        </g>
      </g>
    </svg>
  );
};

export default IconShape2;
