import React from "react";

interface IProps {
  color?: string;
}

const IconShape2: React.FC<IProps> = ({
  color = '#ff6e56'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36" viewBox="0 0 36 36">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_626" data-name="Rectangle 626" width="36" height="35.999" fill={color}/>
        </clipPath>
      </defs>
      <g id="Group_328" data-name="Group 328" transform="translate(0 0)">
        <g id="Group_327" data-name="Group 327" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path id="Path_836" data-name="Path 836" d="M15.888,1.347a3.109,3.109,0,0,0,4.225,0l.6-.571c1.4-1.333,3-.905,3.548.951l.235.795a3.108,3.108,0,0,0,3.659,2.112l.806-.194c1.882-.453,3.051.716,2.6,2.6l-.194.806A3.108,3.108,0,0,0,33.478,11.5l.795.235c1.856.548,2.284,2.145.951,3.548l-.571.6a3.108,3.108,0,0,0,0,4.225l.571.6c1.333,1.4.905,3-.951,3.548l-.8.235a3.109,3.109,0,0,0-2.112,3.659l.194.806c.453,1.882-.715,3.051-2.6,2.6l-.807-.194A3.107,3.107,0,0,0,24.5,33.478l-.235.794c-.549,1.857-2.146,2.285-3.549.951l-.6-.571a3.109,3.109,0,0,0-4.225,0l-.6.572c-1.4,1.333-3,.905-3.548-.951l-.235-.795a3.108,3.108,0,0,0-3.658-2.112l-.807.194c-1.881.453-3.05-.716-2.6-2.6l.194-.807A3.108,3.108,0,0,0,2.522,24.5l-.8-.235c-1.856-.549-2.284-2.145-.951-3.548l.571-.6a3.109,3.109,0,0,0,0-4.225l-.57-.6c-1.334-1.4-.906-3,.951-3.549l.795-.235A3.108,3.108,0,0,0,4.635,7.845L4.44,7.038c-.453-1.882.716-3.05,2.6-2.6l1.209.291A2.667,2.667,0,0,0,11.386,2.92l.117-.4C12.181.229,14.154-.3,15.888,1.347Z" transform="translate(0 0)" fill={color}/>
        </g>
      </g>
    </svg>
  );
};

export default IconShape2;
