import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Experience/slideshow.css'; // Custom CSS file

const ImageRow = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const imageRow = props.data && props.data.images && props.data.images.length > 0 && props.data.images.map( (image: any, index: any) => 
    (
      <img key={index} className="w-full xl:w-[25vw]" src={image[0]} alt="" />
    )
  );

  /* const slides = props.data && props.data.images && props.data.images.length > 0 && props.data.images.map( (image: any, index: any) => 
    (
      <div key={index} className="slide-wrap relative">
        <img src={image[0]} alt="" />
      </div>
    )
  ); */

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '32px',
    // variableWidth: true,
  };

  return (
    <section className="component--image-row relative" ref={sectionEl}>
      <div className="w-full h-1/2 absolute top-1/2 left-0 bg-black" />
      <div className="image-row__images hidden xl:flex gap-3 relative z-2">
        {imageRow}
      </div>
      <div className="block xl:hidden">
        <Slider {...settings} className="mb-5">
          {imageRow}
        </Slider>
      </div>
    </section>
  );
}

export default ImageRow;
