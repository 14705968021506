import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";
import ButtonLink from "../ButtonLink";

const CtaTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component--cta-tile text-black py-30 lg:py-48" ref={sectionEl}>
      <div className="max-w-3xl px-8 lg:px-1.5 mx-auto font-normal">
        <h2 className="text-3xl lg:text-5xl lg:leading-tight italic mb-6">{props.data.title}</h2>
        <p className="text-lg lg:text-2xl lg:leading-normal mb-8 xl:mb-20 font-medium">{props.data.description}</p>
        <p className="text-lg lg:text-xl lg:leading-[1.8] mb-6">{props.data.cta.title}</p>
        <ButtonLink
          color="white" 
          link={props.data.cta.button.url}
          title={props.data.cta.button.title}
        />
      </div>
    </section>
  );
}

export default CtaTile;
