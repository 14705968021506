import React from "react";

interface IProps {
  color?: string;
}

const IconShape1: React.FC<IProps> = ({
  color = '#ff6e56'
}) => {
  return (
    <svg id="Group_326" data-name="Group 326" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36" viewBox="0 0 36 36">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_625" data-name="Rectangle 625" width="36" height="36" fill={color}/>
        </clipPath>
      </defs>
      <g id="Group_325" data-name="Group 325" clipPath="url(#clip-path)">
        <path id="Path_835" data-name="Path 835" d="M19.975,1.395l1.487,4.18a2.1,2.1,0,0,0,2.875,1.192l4.007-1.9a2.1,2.1,0,0,1,2.793,2.793l-1.9,4.007a2.1,2.1,0,0,0,1.192,2.876l4.18,1.486a2.1,2.1,0,0,1,0,3.951l-4.18,1.486a2.1,2.1,0,0,0-1.192,2.876l1.9,4.007a2.1,2.1,0,0,1-2.793,2.794l-4.007-1.9a2.1,2.1,0,0,0-2.875,1.191l-1.487,4.181a2.1,2.1,0,0,1-3.951,0l-1.486-4.181a2.1,2.1,0,0,0-2.876-1.191l-4.007,1.9a2.1,2.1,0,0,1-2.794-2.794l1.9-4.007a2.1,2.1,0,0,0-1.191-2.876L1.394,19.976a2.1,2.1,0,0,1,0-3.951l4.181-1.486a2.1,2.1,0,0,0,1.191-2.876l-1.9-4.007A2.1,2.1,0,0,1,7.655,4.862l4.007,1.9a2.1,2.1,0,0,0,2.876-1.192l1.486-4.18a2.1,2.1,0,0,1,3.951,0" transform="translate(0 0)" fill={color}/>
      </g>
    </svg>
  );
};

export default IconShape1;
