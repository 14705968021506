// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import logo from './logo.svg';
import './App.css';

import AppRoutes from "./routes/Routes";

const App = () => {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
