export const onLoadScrollHandler = (sectionEl: any) => {
  let bounding = sectionEl.current?.getBoundingClientRect();

  if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
    sectionEl.current?.classList.add('on-screen');
  }
}

export const scrollHandler = ( sectionEl: any, scrollEnd: 'centre' | 'end' = 'centre' ) => {
  let bounding = sectionEl.current?.getBoundingClientRect();
  const scrollHeight = scrollEnd === 'centre' ? bounding.height/2 : bounding.height;

  if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
    // sectionEl.current?.classList.add('on-screen');
    if( bounding.top < window.innerHeight-(scrollHeight) ) {
      sectionEl.current?.classList.add('on-screen');
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }
  // else {
  //   sectionEl.current?.classList.remove('on-screen');
  // }
}
