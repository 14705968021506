import { useEffect, useRef } from "react";
import LoadingLink from "../LoadingLink";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";
import ButtonCta from "../ButtonCta";

const FeaturedWork = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const featuredWorkLeft = 
    props.data && 
    props.data.featured_work_chunks && 
    props.data.featured_work_chunks[0] && 
    props.data.featured_work_chunks[0].length > 0 && 
    props.data.featured_work_chunks[0].map( (project: any, index: any) => 
    (
      <LoadingLink key={index} link={'/work/'+project.post_name} className={'block group max-w-sm xl:max-w-none mx-auto xl:mx-0 featured-work__project project-'+index}>
        <img src={project.thumbnail.url} alt={project.post_name} className="transition-all duration-300 group-hover:transform group-hover:scale-105" />
      </LoadingLink>
    )
  );

  const featuredWorkRight = 
    props.data && 
    props.data.featured_work_chunks && 
    props.data.featured_work_chunks[1] && 
    props.data.featured_work_chunks[1].length > 0 && 
    props.data.featured_work_chunks[1].map( (project: any, index: any) => 
    (
      <LoadingLink key={index} link={'/work/'+project.post_name} className={'block group max-w-sm xl:max-w-none mx-auto xl:mx-0 featured-work__project project-'+index}>
        <img src={project.thumbnail.url} alt={project.post_name} className="transition-all duration-300 group-hover:transform group-hover:scale-105" />
      </LoadingLink>
    )
  );

  return (
    <section className="component--featured-work bg-white pt-28 pb-40 xl:pb-72 text-black" ref={sectionEl}>
      <div className="px-8 xl:px-0">
        <h2 className="font-normal font-heading text-5xl xl:text-6xl leading-extratight mb-4 mx-auto max-w-[974px]">{props.data.title}</h2>
        <h3 className="text-2xl font-sans italic text-orange uppercase tracking-widest xl:hidden">Featured Work</h3>
      </div>
      <div className="featured-work__scroll-font hidden xl:block" />
      <div className="max-w-1440px mx-auto mt-25 px-8 xl:px-0 xl:mt-46 block xl:flex xl:justify-between">
        <div className="featured-work__left">
          {featuredWorkLeft}
        </div>
        <div className="featured-work__right">
          {featuredWorkRight}
          <ButtonCta
            link={props.data.button.url} 
            title={props.data.button.title}
          />
        </div>
      </div>
    </section>
  );
}

export default FeaturedWork;
