import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "../store/GlobalContext";

interface IProps {
	className?: string;
	link: string;
	// disabled?: boolean;
  // loading?: boolean;
	// onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const LoadingLink: React.FC<IProps> = ({
  className = '',
  link = '/',
  // value,
  // disabled,
  // loading,
  // onClick = (e: React.MouseEvent<HTMLElement>) => {},
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const globalCtx = useContext(GlobalContext);
  const activeClass = location.pathname === link ? ' active' : '';

  const navigateTest = (e: any) => {
    e.preventDefault();
    
    if(location.pathname !== link) {
      globalCtx.setIsLoading(true);
    }
    
    setTimeout(() => {
      navigate(link);
    }, 1000);// the loading animation lasts 1 second
  }

  return (
    <a
      href={link}
      className={className + activeClass}
      onClick={(e) => navigateTest(e)}
    >
      {children}
    </a>
  )
}

export default LoadingLink;