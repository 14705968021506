import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

import Icon, { Shape1, Shape2, Circle } from "../Icons";

const ImageRow = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl, 'end') };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const cards = props.data && props.data.cards && props.data.cards.length > 0 && props.data.cards.map( (card: any, index: any) => 
    (
      <div key={index} className={'row-of-cards__card max-w-sm xl:max-w-none mx-auto xl:mx-0 border border-grey bg-black text-white text-left pt-7 px-8 pb-12.5 rounded-20px relative card-'+index}>
        {
          index === 0 ?
            <Icon className="w-9 h-9" type={<Shape1 />} />
          : index === 1 ?
            <Icon className="w-9 h-9" type={<Shape2 />} />
          : index === 2 ?
            <Icon className="w-9 h-9" type={<Circle />} />
          : null
        }
        <strong className="block text-darkcharcoal font-normal font-heading text-xl xl:text-3xl leading-none mt-5 mb-1">{card.title}</strong>
        <em className="block text-darkcharcoal text-sm xl:text-base leading-none">{card.subtitle}</em>
        <p className="block text-darkcharcoal text-base leading-6 xl:text-lg xl:leading-breezy mt-7">{card.description}</p>
      </div>
    )
  );

  return (
    <section className="component--row-of-cards relative py-40" ref={sectionEl}>
      <div className="max-w-1440px mx-auto text-orange px-8 xl:px-0">
        <div>
          <h2 className="block row-of-cards__title font-normal text-5xl xl:text-6xl leading-extratight mb-9 font-heading max-w-[774px] mx-auto" dangerouslySetInnerHTML={{ __html: props.data.title }} />
          <span className="block font-heading text-2xl mb-12 xl:text-3xl xl:mb-[88px]">{props.data.subtitle}</span>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-24 xl:gap-15">
          {cards}
        </div>
      </div>
    </section>
  );
}

export default ImageRow;
