import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

const LeftImageWithText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component--left-image-with-text pt-17 pb-25" ref={sectionEl}>
      <div className="max-w-1440px mx-auto">
        <div className="block xl:hidden">
          <p className="left-image-with-text__title text-left text-2xl leading-tight px-8">{props.data.title}</p>
          <img className="w-full my-14" src={props.data.image.url} alt="" width={747} />
          <div dangerouslySetInnerHTML={{ __html: props.data.text }} className="left-image-with-text__text text-left text-2xl leading-tight px-8" />
        </div>
        <div className="hidden xl:flex">
          <img className="max-w-[747px]" src={props.data.image.url} alt="" width={747} />
          <div className="flex items-center content-center flex-wrap">
            <p className="left-image-with-text__title pl-44 pr-40 text-left text-2xl leading-normal">{props.data.title}</p>
            <div dangerouslySetInnerHTML={{ __html: props.data.text }} className="left-image-with-text__text pl-44 pr-40 text-left text-2xl leading-normal" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeftImageWithText;
