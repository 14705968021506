import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";
import ButtonCta from "../ButtonCta";

const ImagesTextCta = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  /* const imageRow = props.data && props.data.images && props.data.images.length > 0 && props.data.images.map( (image: any, index: any) => 
    (
      <img key={index} className="w-1/4 max-w-[480px]" src={image[0]} alt="" />
    )
  ); */

  return (
    <section className="component--images-text-cta pt-30 pb-30 xl:pt-56 xl:pb-52 text-black" ref={sectionEl}>
      <div className="max-w-1440px mx-auto px-8 xl:px-0 xl:flex">
        <div className="xl:w-1/2">
          <h2 className="text-left xl:text-center font-heading font-normal text-5xl xl:text-120px leading-extratight mb-10">{props.data.title}</h2>
          {
            props.data.images &&
            <div className="relative">
              <img src={props.data.images[0][0]} alt="" width={407} className="md:mx-auto xl:ml-29 mb-6" />
              <img src={props.data.images[1][0]} alt="" width={253} className="absolute w-[167px] left-1/2 transform -translate-x-1/2 xl:translate-x-0 -mt-6 xl:mt-0 -translate-y-1/2 xl:translate-y-0 xl:left-auto xl:w-[253px] xl:right-0 xl:top-[77px]" />
              <img src={props.data.images[2][0]} alt="" width={146} className="hidden xl:block absolute left-[156px] bottom-28" />
              <img src={props.data.images[3][0]} alt="" className="hidden xl:block ml-[270px]" />
            </div>
          }
        </div>
        <div className="text-left xl:px-20 xl:w-1/2 pt-32 xl:pt-56">
          <div className="images-text-cta__copy" dangerouslySetInnerHTML={{ __html: props.data.content }} />
          <div className="block relative pb-8 xl:hidden">
            <img src={props.data.images[3][0]} alt="" className="xl:mx-0 mx-auto" />
            <img src={props.data.images[2][0]} alt=""  width={131} className="absolute bottom-8 right-30 xl:right-6 transform translate-y-1/2" />
          </div>
          <ButtonCta
            link={props.data.button.url} 
            title={props.data.button.title}
          />
        </div>
      </div>
    </section>
  );
}

export default ImagesTextCta;
