import { useLocation } from "react-router-dom";
import Icon, { Instagram } from "./Icons";
import LoadingLink from "./LoadingLink";

const Navigation = (props: any) => {
  const location = useLocation();

  const menuItems = props.data && props.data.menu && props.data.menu.length > 0 && props.data.menu.map( (menuItem: any, index: any) => 
    (
      <li key={index}>
        <LoadingLink
          link={'/'+menuItem.slug}
          className={'menu-link flex text-white text-sm leading-extratight rounded-20px border border-white p-2.5 min-w-[120px] h-10 justify-center items-center cursor-pointer relative overflow-hidden transition-all duration-500 hover:border-orange ' + menuItem.classes.toString().replaceAll(',', ' ')}
        >
          <span className="menu-link__bg block w-0 h-full bg-orange absolute left-0 transition-all duration-500" />
          <span className="z-2">{ menuItem.title}</span>
        </LoadingLink>
      </li>
    )
  );

  /* const socialIcons = header && header.social && header.social.length > 0 && header.social.map( (social: any, index: any) => 
    (
      social.icon === 'facebook' ?
      <li key={index} className="inline-block ml-12 lg:ml-5 first:ml-0">
        <a href={social.link} target="_blank" rel="noreferrer">
          <Icon className="w-4" type={<Facebook />} />
        </a>
      </li>
      
      : social.icon === 'instagram' ?
      <li key={index} className="inline-block ml-12 lg:ml-5 first:ml-0">
        <a href={social.link} target="_blank" rel="noreferrer">
          <Icon className="w-8" type={<Instagram />} />
        </a>
      </li>
      
      : social.icon === 'linkedin' ?
      <li key={index} className="inline-block ml-12 lg:ml-5 first:ml-0">
        <a href={social.link} target="_blank" rel="noreferrer">
          <Icon className="w-8" type={<LinkedIn />} />
        </a>
      </li>
      
      : null
    )
  ); */

  return (
    <nav className="max-w-1440px mx-auto flex justify-between items-center py-11 font-heading">
      <div className="flex gap-x-16 items-start">
        <LoadingLink
          link="/" 
          className="w-auto h-15 block hover:opacity-80"
        >
          <img className="w-auto h-full" src={props.data.logo[0]} alt="" />
        </LoadingLink>
        <a className="flex items-center gap-x-2 mt-9" href={props.data.social_link} target="_blank" rel="noreferrer">
          <Icon className="w-4 h-4" type={<Instagram color="#ffffff" />} />
          <span className="font-heading text-sm leading-6 uppercase inline-block text-white">Instagram</span>
        </a>
      </div>
      <ul className="flex gap-2">
        {menuItems}
      </ul>
    </nav>
  )
}

export default Navigation;