const Unloading = () => {
  return (
    <>
      <div className="unloading__first bg-orange w-screen h-screen fixed top-0 left-0 z-1000" />
      <div className="unloading__second bg-white w-screen h-screen fixed top-0 left-0 z-999" />
    </>
  );
};

export default Unloading;
