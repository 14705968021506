import React from "react";

interface IProps {
  color?: string;
}

const IconHamburgerMenu: React.FC<IProps> = ({
  color = '#ff6e56'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.001" height="22" viewBox="0 0 30.001 22">
      <path id="Hamburger_Menu_Button" data-name="Hamburger Menu Button" d="M454-22.517v-1.692h30v1.692Zm0-10.154v-1.693h30v1.693Zm0-10.154v-1.692h30v1.692Z" transform="translate(-454 44.517)" fill={color} />
    </svg>
  );
};

export default IconHamburgerMenu;
