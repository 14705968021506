import { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route } from 'react-router-dom';

import Page from "../pages/Page";
import Project from "../pages/Project";
import NotFound from "../pages/NotFound";

const apiUrl = process.env.REACT_APP_API_URL;

const AppRoutes = () => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    getMenus();
  }, []);

  const getMenus = () => {
    axios.get(`${apiUrl}/pages/get`)
    .then( response =>  {
      setMenuItems(response.data);
    })
    .catch( error => {
      console.error(error);
    });
  }

  const routeList = menuItems && menuItems.length > 0 && menuItems.map( (route: any, index: any) => 
    (
      <Route key={index} path={'/'+route.slug} element={<Page slug={route.slug} />} />
    )
  );

  return (
    <Routes>
      <Route path="/" element={<Page slug="home" />} />
      {routeList}
      <Route path="/work/:slug" element={<Project />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;