import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from "../components/Loading";
import Unloading from "../components/Unloading";

const apiUrl = process.env.REACT_APP_API_URL;

const GlobalContext = React.createContext({} as any);

export const GlobalContextProvider = (props: any) => {
  const [globalContent, setGlobalContent] = useState() as any;
  const [isLoading, setIsLoading] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    axios.get(`${apiUrl}/global/get`)
    .then( response =>  {
      let global = {
        apiUrl: apiUrl,
        global: response.data,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        isUnloading: isUnloading,
        setIsUnloading: setIsUnloading
      }
      setGlobalContent(global);
    })
    .catch( error => {
      console.error(error);
    });
  }, []);

  return (
    <>
    {
      globalContent && 
      <GlobalContext.Provider value={globalContent}>
        {isLoading && <Loading />}
        {isUnloading && <Unloading />}
        {props.children}
      </GlobalContext.Provider>
    }
    </>
  );
};

export default GlobalContext;