import React from "react";

interface IProps {
  color?: string;
}

const IconArrow: React.FC<IProps> = ({
  color = '#ffffff'
}) => {
  return (
    <svg id="Arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="120" height="119.599" viewBox="0 0 120 119.599">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_21" data-name="Rectangle 21" width="120" height="119.599" fill="none"/>
        </clipPath>
      </defs>
      <g id="Group_4" data-name="Group 4" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path id="Path_5" data-name="Path 5" d="M60,0a60,60,0,1,0,60,60A60,60,0,0,0,60,0m0,118.181A58.181,58.181,0,1,1,118.181,60,58.247,58.247,0,0,1,60,118.181M73.235,70.81l2.515,2.515L60,89.075,57.485,86.56,44.25,73.325l2.517-2.515L58.221,82.266V30.925h3.557V82.266Z" transform="translate(0.201 -0.2)" fill={color}/>
      </g>
    </svg>
  );
};

export default IconArrow;
