import { useEffect, useState, useContext } from "react";
import axios from "axios";

import GlobalContext from "../store/GlobalContext";
import Header from "../components/Header";
import Footer from "../components/Footer";

import CalloutText from "../components/Content/CalloutText";
import CaseStudiesList from "../components/Content/CaseStudiesList";
import ContactTile from "../components/Content/ContactTile";
import CtaTile from "../components/Content/CtaTile";
import ExperienceInitial from "../components/Content/Experience/Initial";
import ExperienceMockups from "../components/Content/Experience/Mockups";
import ExperienceFinished from "../components/Content/Experience/Finished";
import FeaturedWork from "../components/Content/FeaturedWork";
import FullWidthImage from "../components/Content/FullWidthImage";
import HeroSection from "../components/Content/HeroSection";
import ImageRow from "../components/Content/ImageRow";
import ImagesTextCta from "../components/Content/ImagesTextCta";
import LeftImageWithText from "../components/Content/LeftImageWithText";
import RowOfCards from "../components/Content/RowOfCards";
import Services from "../components/Content/Services";
// import StatsCountUp from "../components/Content/StatsCountUp";
import StatsRiseUp from "../components/Content/StatsRiseUp";
import TextBox from "../components/Content/TextBox";

const Page = (props: any) => {
  const globalCtx = useContext(GlobalContext);
  const [pageData, setPageData] = useState() as any;

  useEffect(() => {
    axios.get(`${globalCtx.apiUrl}/pages/single?slug=${props.slug}`)
    .then( response =>  {
      setPageData(response.data);
      window.scrollTo(0, 0);

      globalCtx.setIsLoading(false);
      globalCtx.setIsUnloading(true);

      setTimeout(() => {
        globalCtx.setIsUnloading(false);
      }, 1000);// the animation lasts 1 second
    })
    .catch( error => {
      console.error(error);
    });
  }, [props.slug, globalCtx]);

  const pageContent = pageData && pageData.page_content && pageData.page_content.length > 0 && pageData.page_content.map( (content: any, index: any) => 
    (
      content.content_type === 'callout_text' ?
        <CalloutText key={content.component_id} data={content} />
		  : content.content_type === 'case_studies_list' ?
        <CaseStudiesList key={content.component_id} data={content} />
		  : content.content_type === 'contact_tile' ?
        <ContactTile key={content.component_id} data={content} />
		  : content.content_type === 'cta_tile' ?
        <CtaTile key={content.component_id} data={content} />
		  : content.content_type === 'experience_initial' ?
        <ExperienceInitial key={content.component_id} data={content} />
		  : content.content_type === 'experience_mockups' ?
        <ExperienceMockups key={content.component_id} data={content} />
		  : content.content_type === 'experience_finished' ?
        <ExperienceFinished key={content.component_id} data={content} />
		  : content.content_type === 'featured_work' ?
        <FeaturedWork key={content.component_id} data={content} />
		  : content.content_type === 'fullwidth_image' ?
        <FullWidthImage key={content.component_id} data={content} />
		  : content.content_type === 'hero_section' ?
        <HeroSection key={content.component_id} data={content} />
		  : content.content_type === 'image_row' ?
        <ImageRow key={content.component_id} data={content} />
		  : content.content_type === 'images_text_and_cta' ?
        <ImagesTextCta key={content.component_id} data={content} />
		  : content.content_type === 'left_image_with_text' ?
        <LeftImageWithText key={content.component_id} data={content} />
		  : content.content_type === 'row_of_cards' ?
        <RowOfCards key={content.component_id} data={content} />
		  : content.content_type === 'services' ?
        <Services key={content.component_id} data={content} />
		  : content.content_type === 'stats' ?
        <StatsRiseUp key={content.component_id} data={content} />
		  : content.content_type === 'text_box' ?
        <TextBox key={content.component_id} data={content} />
      : null
    )
  );

  return (
    <div className={'bg-black '+(pageData && 'page--'+pageData.slug)}>
      <Header data={globalCtx.global.header} />
      {pageContent}
      <Footer data={globalCtx.global} />
    </div>
  );
}

export default Page;
