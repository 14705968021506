import LoadingLink from "./LoadingLink";
import Icon, { RightArrow } from "./Icons";

interface IProps {
	link: string;
	title: string;
  color: 'black' | 'white';
}

const ButtonLink: React.FC<IProps> = ({
  link,
  title,
  color,
}) => {
  return (
    color === 'white' ?
    (
      <LoadingLink
        link={link}
        className="flex justify-center items-center mx-auto rounded-[30px] bg-white w-full lg:w-[440px] h-15 p-[18px] text-xl leading-6 lg:text-xl lg:leading-extratight group"
      >
        {title}
        <Icon className="ml-6 lg:ml-5 w-11 relative left-0 transition-all duration-500 ease-in-out group-hover:left-2" type={<RightArrow color="#000000" />} />
      </LoadingLink>
    ) : (
      <LoadingLink
        link={link}
        className="flex justify-center items-center mx-auto rounded-[30px] bg-black text-white w-full lg:w-[440px] h-15 p-[18px] text-xl leading-6 lg:text-xl lg:leading-extratight group"
      >
        {title}
        <Icon className="ml-6 lg:ml-5 w-11 relative left-0 transition-all duration-500 ease-in-out group-hover:left-2" type={<RightArrow color="#ffffff" />} />
      </LoadingLink>
    )
  )
}

export default ButtonLink;