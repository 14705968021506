import LoadingLink from "./LoadingLink";
import Icon, { RightArrow } from "./Icons";

interface IProps {
	link: string;
	title: string;
}

const ButtonCta: React.FC<IProps> = ({
  link,
  title,
}) => {
  return (
    <LoadingLink
      link={link}
      className="block w-full md:w-[412px] h-[143px] p-5 pb-[87px] font-heading text-xl bg-yellow rounded-14px text-left mt-25 lg:mt-65.5 mx-auto relative group"
    >
      {title}
      <Icon className="w-[87px] absolute right-5 bottom-5 transition-all duration-500 ease-in-out group-hover:right-3" type={<RightArrow />} />
    </LoadingLink>
  )
}

export default ButtonCta;