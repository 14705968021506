import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

const FullWidthImage = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section 
      ref={sectionEl} 
      className="component--fullwidth-image h-[360px] lg:h-auto overflow-hidden" 
      style={{
        background: 'url('+props.data.image[0]+') center',
        backgroundSize: 'auto 105%'
      }}
    >
      <img className="block invisible lg:visible w-full h-auto" src={props.data.image[0]} alt="" />
    </section>
  );
}

export default FullWidthImage;
