import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

const TextBox = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const columns = props.data && props.data.columns && props.data.columns.length > 0 && props.data.columns.map( (column: any, index: any) => 
    (
      <div key={index} dangerouslySetInnerHTML={{ __html: column }} className="text-box__column" />
    )
  );

  return (
    <section className="component--text-box pt-17 pb-25" ref={sectionEl}>
      <div className="max-w-1440px mx-auto lg:pl-[220px] lg:pr-[166px] text-left px-8 lg:px-0">
        <div dangerouslySetInnerHTML={{ __html: props.data.title }} className="text-5xl leading-tight italic max-w-4xl lg:pr-17 mb-20" />
        <div className="text-box__columns lg:grid justify-between text-2xl leading-normal">
          {columns}
        </div>
      </div>
    </section>
  );
}

export default TextBox;
