import React from "react";

interface IProps {
  color?: string;
}

const IconRightArrow: React.FC<IProps> = ({
  color = '#000000'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="87.061" height="47.16" viewBox="0 0 87.061 47.16">
      <path id="RightArrow" d="M63.481,0,59.716,3.765,76.864,20.917H0v5.329H76.864L59.716,43.394l3.766,3.766L83.3,27.348l3.766-3.766Z" transform="translate(0 0)" fill={color}/>
    </svg>
  );
};

export default IconRightArrow;
