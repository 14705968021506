import Navigation from "./Navigation";
import MobileNav from "./MobileNav";

const Header = (props: any) => {
  return (
    <header
      className="site-header w-full px-8"
    >
      <div className="hidden min860px:block">
        <Navigation data={props.data} />
      </div>
      <div className="min860px:hidden">
        <MobileNav data={props.data} />
      </div>
    </header>
  )
}

export default Header;