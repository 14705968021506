import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";
import TextRise from "../TextRise";

const CalloutText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const columns = props.data && props.data.columns && props.data.columns.length > 0 && props.data.columns.map( (column: any, index: any) => 
    (
      <p key={index} className="text-center mx-auto xl:mx-0 mb-6 xl:mb-0 xl:text-left leading-6 text-lg xl:leading-breezy max-w-[360px]" dangerouslySetInnerHTML={{ __html: column }} />
    )
  );

  return (
    <section className="component--callout-text py-30 xl:py-40 xl:mt-[200px]" ref={sectionEl}>
      <div className="callout-text__content max-w-1440px mx-auto text-orange pt-20 px-8 xl:px-0">
        <em className="block font-normal not-italic font-heading text-lg leading-6 max-w-[200px] mx-auto xl:max-w-none xl:text-4xl xl:leading-none mb-9">{props.data.subtitle}</em>
        <TextRise className="delay-1000">
          <h2 className="callout-text__title block font-normal font-display uppercase text-[7.5rem] leading-[6.5rem] xl:text-220px xl:leading-none mb-3">{props.data.title}</h2>
        </TextRise>
        <div className="xl:flex xl:gap-14 xl:justify-center mx-auto">
          {columns}
        </div>
      </div>
    </section>
  );
}

export default CalloutText;
