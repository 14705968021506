import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../../util/helpers";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slideshow.css'; // Custom CSS file

const ExperienceMockups = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const slides = props.data && props.data.images && props.data.images.length > 0 && props.data.images.map( (image: any, index: any) => 
    (
      <div key={index} className="slide-wrap relative">
        <img src={image[0]} alt="" />
      </div>
    )
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    // centerPadding: '1%',
    variableWidth: true,
  };

  return (
    <section className="component-experience-mockups" ref={sectionEl}>
      <div className="max-w-3xl mx-auto px-8 xl:px-10 text-orange">
        <h2 className="italic text-5xl xl:text-7xl mb-7">{props.data.title}</h2>
        <p className="xl:px-12 text-lg leading-6 xl:leading-7 mb-17">{props.data.description}</p>
      </div>
      <Slider {...settings} className="mb-32 xl:mb-5">
        {slides}
      </Slider>
    </section>
  );
}

export default ExperienceMockups;
