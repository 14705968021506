import React from "react";

interface IProps {
	className?: string;
  type: React.ReactNode;
}

const Icon: React.FC<IProps> = ({
  className = '',
  type = ''
}) => {
	return (
    <span className={'inline-block svg-icon ' + className}>
      {type}
    </span>
  );
};

export default Icon;
export {default as Instagram} from './Social/Instagram';
export {default as Sparkle} from './Main/Sparkle';
export {default as Arrow} from './Main/Arrow';
export {default as Shape1} from './Main/Shape1';
export {default as Shape2} from './Main/Shape2';
export {default as Circle} from './Main/Circle';
export {default as RightArrow} from './Main/RightArrow';
export {default as HamburgerMenu} from './Main/HamburgerMenu';
