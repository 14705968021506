import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingLink from "../components/LoadingLink";

import GlobalContext from "../store/GlobalContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Icon, { Arrow } from "../components/Icons";

const Project = () => {
  const globalCtx = useContext(GlobalContext);
  const { slug } = useParams();
  const [pageData, setPageData] = useState() as any;
  // const siteLogo = globalCtx.global.site_logo_url;

  useEffect(() => {
    axios.get(`${globalCtx.apiUrl}/projects/single?slug=${slug}`)
    .then( response =>  {
      setPageData(response.data);
      window.scrollTo(0, 0);

      globalCtx.setIsLoading(false);
      globalCtx.setIsUnloading(true);

      setTimeout(() => {
        globalCtx.setIsUnloading(false);
      }, 1000);// the animation lasts 1 second
    })
    .catch( error => {
      console.error(error);
    });
  }, [globalCtx.apiUrl, slug]);

  const photos = pageData && pageData.photos && pageData.photos.length > 0 && pageData.photos.map( (photo: any, index: any) => 
    (
      <img key={index} className="block w-full h-auto" src={photo[0]} alt="" />
    )
  );

  /* const getTags = (tags: any[]) => {
    return (tags.map( (tag: {[key: string]: any}, index: number) => 
      (
        <li key={index} className="inline-block text-base md:text-2xl text-lightgrey underline mr-2 last:mr-0 md:mr-9 md:last:mr-0">
          {tag.name}
        </li>
      )
    ))
  }; */

  return (
    <>
    { pageData &&
      <div className={'bg-black relative '+(slug && 'single-project project--'+slug)}>
        <div className="absolute top-0 left-0 w-full z-50">
          <Header data={globalCtx.global.header} />
        </div>
        { pageData.hero_image &&
          <div
            className="project__hero relative top-28 lg:top-0 h-60 lg:h-[768px]"
            style={{
              background: `url('${pageData.hero_image[0]}') center no-repeat`,
              backgroundSize: 'cover',
            }}
          />
        }
        <div className="xl:flex xl:gap-8 max-w-1440px mx-auto pt-36 pb-15 xl:py-40">
          <div className="text-left text-white font-normal xl:min-w-[436px] px-8 xl:px-0">
            <h1 dangerouslySetInnerHTML={{ __html: pageData.title }} className="font-heading text-3xl leading-none xl:text-5xl xl:leading-tight mb-4 xl:mb-6" />
            <em className="block text-orange text-xs leading-normal xl:text-sm mb-4 xl:mb-3">My role on the project</em>
            <p dangerouslySetInnerHTML={{ __html:pageData.project_role }} className="text-lg leading-6 xl:text-lg font-heading mb-10 xl:mb-0" />
          </div>
          <p dangerouslySetInnerHTML={{ __html:pageData.description }} className="text-white text-left text-sm leading-6 xl:text-lg font-heading px-8 xl:px-0" />
        </div>
        <div className="max-w-1440px mx-auto xl:mb-52">
          {photos}
        </div>
        <div className="bg-white text-black">
          <div className="max-w-1440px mx-auto py-15 xl:py-[135px] px-8 xl:px-47 xl:flex xl:items-center xl:gap-12.5">
            <img src={pageData.next_project.thumbnail.sizes['portfolio-thumb']} alt="" width={280} className="hidden xl:block" />
            <LoadingLink link={'/work/'+pageData.next_project.slug} className="xl:flex xl:gap-12.5 xl:items-center">
              <Icon className="w-25 h-25 -rotate-90 xl:hidden inline-block" type={<Arrow color="#000" />} />
              <span className="block mt-5 xl:mt-0 xl:inline-block font-heading text-4xl leading-close xl:text-[6.25rem] xl:leading-none">Next project</span>
              <Icon className="w-25 h-25 -rotate-90 hidden xl:inline-block" type={<Arrow color="#000" />} />
            </LoadingLink>
          </div>
        </div>
        <Footer data={globalCtx.global} / >
      </div>
    }
    </>
  );
}

export default Project;
