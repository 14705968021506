import { useEffect, useState } from "react";
import LoadingLink from "../../LoadingLink";
import Icon, { Arrow } from "../../Icons";

const Featured = (props: any) => {
  const [oddElements, setOddElements] = useState([]) as any;
  const [evenElements, setEvenElements] = useState([]) as any;

  useEffect(() => {
    const oddElements = [];
    const evenElements = [];

    for (let i = 0; i < props.data.length; i++) {
      if (i % 2 === 0) {
        oddElements.push(props.data[i]);
      } else {
        evenElements.push(props.data[i]);
      }
    }

    setOddElements(oddElements);
    setEvenElements(evenElements);
  }, [props.data]);

  const oddCaseStudies = oddElements && oddElements.length > 0 && oddElements.map( (project: any, index: any) => 
    (
      <div key={project.ID} className={'featured-project project mb-30' + (index % 2 === 0 ? ' project--small ml-15' : ' project--large')}>
        <div className="featured-project-inner relative w-full h-full text-center overflow-hidden">
          <div className="featured-project-front absolute w-full h-full bg-yellow text-black">
            <img src={project.thumbnail.sizes['portfolio-thumb']} alt="" className="w-full" />
          </div>
          <div className="featured-project-back absolute -left-full w-full h-full bg-orange text-black pt-20 pb-16 px-12.5 transition-all ease-in-out duration-700 flex items-center">
            <div>
              <strong className="block font-heading font-normal text-5xl mb-6 pt-2.5">{project.post_title}</strong>
              <p dangerouslySetInnerHTML={{ __html: project.short_description }} className="font-heading font-normal text-base mb-6 overflow-hidden" />
              <p className="font-heading font-normal text-sm mb-12"><em>{project.services}</em></p>
              <LoadingLink link={'/work/'+project.post_name}>
                <Icon className="w-15 h-15 -rotate-90" type={<Arrow color="#000" />} />
              </LoadingLink>
            </div>
          </div>
        </div>
      </div>
    )
  );

  const evenCaseStudies = evenElements && evenElements.length > 0 && evenElements.map( (project: any, index: any) => 
    (
      <div key={project.ID} className={'featured-project project mb-30' + (index % 2 === 0 ? ' project--large' : ' project--small ml-15')}>
        <div className="featured-project-inner relative w-full h-full text-center overflow-hidden">
          <div className="featured-project-front absolute w-full h-full bg-yellow text-black">
            <img src={project.thumbnail.sizes['portfolio-thumb']} alt="" className="w-full" />
          </div>
          <div className="featured-project-back absolute -left-full w-full h-full bg-orange text-black pt-20 pb-16 px-12.5 transition-all ease-in-out duration-700 flex items-center">
            <div>
              <strong className="block font-heading font-normal text-5xl mb-6 pt-2.5">{project.post_title}</strong>
              <p dangerouslySetInnerHTML={{ __html: project.short_description }} className="font-heading font-normal text-base mb-6 overflow-hidden" />
              <p className="font-heading font-normal text-sm mb-12"><em>{project.services}</em></p>
              <LoadingLink link={'/work/'+project.post_name}>
                <Icon className="w-15 h-15 -rotate-90" type={<Arrow color="#000" />} />
              </LoadingLink>
            </div>
          </div>
        </div>
      </div>
    )
  );

  const allCaseStudies = props.data && props.data.length > 0 && props.data.map( (project: any, index: any) => 
    (
      <div key={project.ID} className="max-w-md mx-auto mb-8">
        <LoadingLink link={'/work/'+project.post_name}>
          <img src={project.thumbnail.sizes['portfolio-thumb']} alt="" className="w-full" />
        </LoadingLink>
      </div>
    )
  );

  return (
    <>
    <div className="case-studies-list__featured hidden xl:flex justify-between">
      <div>
        {oddCaseStudies}
      </div>
      <div className="mt-[85px]">
        {evenCaseStudies}
      </div>
    </div>
    <div className="block xl:hidden">
      {allCaseStudies}
    </div>
    </>
  )
}

export default Featured;