interface IProps {
	className?: string;
}

const TextRise: React.FC<IProps> = ({
  className = '',
  children,
}) => {
  return (
    <div className={'text-rise relative overflow-hidden ' + className}>
      <span className="text-rise__text block leading-none">{children}</span>
    </div>
  )
}

export default TextRise;