import React from "react";

interface IProps {
  color?: string;
}

const IconSparkle: React.FC<IProps> = ({
  color = '#ff6e56'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54.988" height="54.988" viewBox="0 0 54.988 54.988">
      <path id="Path_834" data-name="Path 834" d="M25.286,0V19.422L15.4,2.7,11.6,4.948l9.882,16.72L4.472,12.3,2.343,16.17l16.563,9.117H0V29.7H19.422L2.7,39.584l2.246,3.8L21.668,33.5,12.3,50.516l3.867,2.129,9.116-16.563V54.988H29.7V35.566l9.883,16.72,3.8-2.246L33.5,33.32l17.015,9.365,2.129-3.867L36.081,29.7H54.988V25.287H35.565L52.286,15.4l-2.247-3.8L33.32,21.487,42.685,4.472,38.817,2.343,29.7,18.907V0Z" transform="translate(0 0)" fill={color}/>
    </svg>
  );
};

export default IconSparkle;
