const Loading = () => {
  return (
    <>
      <div className="loading__first bg-white w-screen h-screen fixed top-0 left-0 z-1000" />
      <div className="loading__second bg-orange w-screen h-screen fixed top-0 left-0 z-1000" />
    </>
  );
};

export default Loading;
